<template>
  <span>
    <span>Dates: </span>
    <span>{{ getDates(validFrom, validTo) }}</span>
  </span>
</template>

<script>
export default {
  props: {
    validFrom: String,
    validTo: String,
  },
  methods: {
    getDates(validFrom, validTo) {
      const dateFrom = new Date(validFrom);
      const dateTo = new Date(validTo);

      if (dateFrom.getUTCMonth() !== dateTo.getUTCMonth()) {
        return `${this.formatDate(dateFrom)} - ${this.formatDate(dateTo)}`;
      }

      if (dateFrom.getUTCDate() !== dateTo.getUTCDate()) {
        return `${dateFrom.getUTCDate()} - ${this.formatDate(dateTo)}`;
      }

      return this.formatDate(dateFrom);
    },

    formatDate(date) {
      const day = date.getUTCDate().toString().padStart(2, 0);
      const month = (date.getUTCMonth() + 1).toString().padStart(2, 0);
      const year = date.getUTCFullYear().toString();
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style></style>
